.App {
  text-align: center;
}

.layout {
	min-height: 100vh;
}

.ant-layout-header {
	background-color: #11489F;
	height: 30px;
}